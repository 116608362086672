"use strict";

// Class Definition
var KTLogin = function() {
	var _login;

	var _showForm = function(form) {
			var cls = 'login-' + form + '-on';
			var form = 'kt_login_' + form + '_form';

			_login.removeClass('login-forgot-on');
			_login.removeClass('login-signin-on');
			_login.removeClass('login-signup-on');

			_login.addClass(cls);

			KTUtil.animateClass(KTUtil.getById(form), 'animate__animated animate__backInUp');
	}

	var _handleSignInForm = function() {
			var validation;

			// Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
	validation = FormValidation.formValidation(
		KTUtil.getById('kt_login_signin_form'),
		{
			fields: {
				email: {
					validators: {
						notEmpty: {
							message: 'Ingresa tu email'
						}
					}
				},
				password: {
					validators: {
						notEmpty: {
							message: 'Ingresa tu contraseña'
						}
					}
				}
			},
			plugins: {
				trigger: new FormValidation.plugins.Trigger(),
				//submitButton: new FormValidation.plugins.SubmitButton(),
				//defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Uncomment this line to enable normal button submit after form validation
				bootstrap: new FormValidation.plugins.Bootstrap()
			}
		}
	);



						$("#kt_login_signin_form").submit(function (event) {
							event.preventDefault(); //prevent default action 

							validation.validate().then(function (status) {

								if (status == 'Valid') {
									var form_data = $("#kt_login_signin_form").serialize(); //Encode form elements for submission
									$.ajax({
										url: 'validate.php',
										type: 'POST',
										data: form_data,
										success: function (response) {
											var jsonData = JSON.parse(response);

											if (jsonData.alert == "success") {
												location.href = "index.php"
											} else {

												Swal.fire({
													icon: 'error',
													title: 'Error',
													text: jsonData.message,
													showConfirmButton: false,
													timer: 1500
												})
											}
										}
									});
								} else {
									swal.fire({
										text: "Hemos detectado errores, asegurate de escribir tu correo y contraseña correctamente.",
										icon: "error",
										buttonsStyling: false,
										confirmButtonText: "Ok!",
										customClass: {
											confirmButton: "btn font-weight-bold btn-light-primary"
										}
									}).then(function () {
										KTUtil.scrollTop();
									});
								}



							});


						});

			// Handle forgot button
			$('#kt_login_forgot').on('click', function (e) {
					e.preventDefault();
					_showForm('forgot');
			});

			// Handle signup
/* 			$('#kt_login_signup').on('click', function (e) {
					e.preventDefault();
					_showForm('signup');
			}); */
	}

	var _handleForgotForm = function(e) {
			var validation;

			// Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
			validation = FormValidation.formValidation(
		KTUtil.getById('kt_login_forgot_form'),
		{
			fields: {
				email: {
					validators: {
						notEmpty: {
							message: 'Email es requerido'
						},
						emailAddress: {
							message: 'El valor no es un email valido'
						}
					}
				}
			},
			plugins: {
				trigger: new FormValidation.plugins.Trigger(),
				bootstrap: new FormValidation.plugins.Bootstrap()
			}
		}
	);

			// Handle submit button
			$('#kt_login_forgot_submit').on('click', function (e) {
					e.preventDefault();

					validation.validate().then(function(status) {
					if (status == 'Valid') {
						var email=$('input[name=email_recover]').val();
             $.ajax({
              url: 'recover.php',
              type: 'POST',
              data: {recover_email:email},
              success: function (response) {
                var jsonData = JSON.parse(response);
                if (jsonData.alert == "success") {
                  Swal.fire({
                    icon: 'success',
                    title: 'Exito',
                    text: jsonData.message,
                    showConfirmButton: false,
                    timer: 1700
                  })
                  setTimeout(function() { window.location.reload() },1500);
           
                }  else {
                  
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: jsonData.message,
                    showConfirmButton: true,
                    confirmButtonText: 'OK',
                  })
                }
              }
            });
        
			} else {
				swal.fire({
									text: "Completa los campos",
									icon: "error",
									buttonsStyling: false,
									confirmButtonText: "Ok!",
											customClass: {
							confirmButton: "btn font-weight-bold btn-light-primary"
						}
							}).then(function() {
					KTUtil.scrollTop();
				});
			}
			});
			});

			// Handle cancel button
			$('#kt_login_forgot_cancel').on('click', function (e) {
					e.preventDefault();

					_showForm('signin');
			});
	}

	// Public Functions
	return {
			// public functions
			init: function() {
					_login = $('#kt_login');

					_handleSignInForm();
					_handleForgotForm();
			}
	};
}();

// Class Initialization
jQuery(document).ready(function() {
	KTLogin.init();
});
